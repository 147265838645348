:root {
  --brand-1: #084baf;
  --brand-2: #2e83fd;
  --brand-3: #005be9;
  --marked-color: #fff;
  --shadow-1: 0 3px 4px 0 rgba(0, 0, 0, 0.04);
  --shadow-2: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  --shadow-3: 0 6px 10px 0 rgba(0, 0, 0, 0.08);
}
.ChatApp,
.MessageContainer,
.Navbar,
.Message .Bubble,
.QuickReplies,
.ChatFooter {
  background-repeat: no-repeat;
  background-size: cover;
}
.ChatApp {
  background-color: #ffffff;
}
.MessageContainer {
  background-image: linear-gradient(180deg, #ffffff 0%, #f2f2f2 100%);
}
.Navbar {
  background-color: #ffffff;
}
.Navbar-title {
  color: rgba(0, 0, 0, 0.87);
}
.Message.left .Bubble {
  background-color: rgba(233, 227, 227, 0.4);
}
.Message.right .Bubble {
  color: #fff;
}
.Btn--primary {
  color: #fff;
}
.QuickReplies,
.ChatFooter {
  background-color: rgba(255, 255, 255, 0.95);
}
.Message.left .Bubble {
  border-radius: 4px 20px 20px 20px;
}
.Message.right .Bubble {
  border-radius: 20px 4px 20px 20px;
}
.Card {
  border-radius: 12px;
}
.Btn {
  border-radius: 16px;
}
.QuickReply {
  border-radius: 20px;
}
