body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.recordButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 40px;
  background: orange;
  color: white;
  cursor: pointer;
  border-radius: 100px;
  border: none;
  box-shadow: 0 5px 10px #ccc;
  z-index: 99;
}

.container {
  margin: auto;
  height: 100vh;
}


.robotpic {
  position: fixed;
  top: 5px;
  left: 3vh;
  z-index: 99;
  height: 15vh;
  width: auto;
}

